import React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import tronBull from "../../images/academy/TRON Bull holding magnify glass.png";
import tronBooks from "../../images/academy/bookshelves.png";

const Academy = ({data}) => {

    let partners = data.allWpAcademyPartner.edges;


    return (
        <div className="tron-academy">
            <Helmet>
                <title>Academy Program | TRONDAO</title>
                <meta name="description"
                      content="Industry-leading curriculum provides a comprehensive blockchain education for technology leaders. We empower students to change the world with Blockchain!"/>
                <meta property="og:description"
                      content="Industry-leading curriculum provides a comprehensive blockchain education for technology leaders. We empower students to change the world with Blockchain!"/>
                <meta property="og:title" content="Academy Program | TRONDAO"/>
            </Helmet>
            <div className="row no-gutters tron-academy-top">
                <div className="col-12 text-center">
                    <h1 className="text-uppercase">TRON Academy</h1>
                    <h2>Equipping the Next Generation of Blockchain Tech Leaders</h2>
                    <div className="d-flex w-100 justify-content-center mt-3">
                        <a href="https://docs.google.com/forms/d/1rUl5a-LCIMvPoP-F6SMuCXSG-iOHMK2nrxkhGDis1HA/" id="tron-academy-research-cta-top" target="_blank" rel="noopenner noreferrer">
                            <button>Join TRON Academy</button>
                        </a>
                    </div>
                    <img className="img-fluid" src={tronBooks} alt="tron-bulls-bookshelves"/>
                </div>
            </div>
            <div className="row no-gutters tron-academy-middle">
                <div className="col-12 text-center justify-content-center">
                    <h3 className="text-uppercase">OUR MISSION</h3>
                    <div className="d-flex w-100 justify-content-center">
                        <h4>TRON Academy exists to equip the next generation of blockchain leaders and developers from the world’s most prestigious learning environments and universities with a comprehensive and innovative education that empowers students to change the world with blockchain.</h4>
                    </div>
                </div>
            </div>
            <div className="tron-academy-partners-background">
                <div className="row no-gutters tron-academy-partners my-5">
                    <div className="col-12 text-center justify-content-center">
                        <h3 className="text-uppercase">Partnered Schools</h3>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-center my-5">
                    <div className="tron-academy-partners-card-section d-flex flex-wrap justify-content-center">
                        {
                            partners.length > 0 && partners.map((partner => {
                                return <div className="tron-academy-partners-card-wrapper">
                                    <div
                                    className="tron-academy-partners-card justify-content-center text-center align-items-center d-flex"
                                    key={"key-" + partner.node.title}>
                                    <img className="img-fluid"
                                         src={partner.node.academy_partner_info.partnerLogo.sourceUrl}
                                         alt={partner.node.title}/>
                                </div>
                                <h4>{partner.node.title}</h4>
                                </div>
                            }))
                        }

                    </div>
                </div>
                <div className="col-12">
                    <p className="text-center">More to come!</p>
                </div>
            </div>
            <div className="row no-gutters tron-academy-research">
                <div className="col-lg-5 col-12 offset-0 offset-lg-1 px-5">
                    <div className="text-uppercase tron-academy-research-text">TRON Research</div>
                    <div className="tron-academy-research-background"/>
                    <p>ATTN university students with decentralized aspirations: </p>
                    <p className="mt-2">Blockchain Clubs and student teams are welcomed to submit research projects and reports on innovative and sustainable use cases for blockchain technology. With TRON's large community, submissions will be shared, viewed, and interacted with by others, providing an open platform for researchers to share their findings and further develop their support network. </p>
                    <p className="mt-2">WHY? Because TRON wants next gen leaders to join in the effort to empower decentralized commerce and community for every human on the planet!</p>
                    <a href="https://docs.google.com/forms/d/1rUl5a-LCIMvPoP-F6SMuCXSG-iOHMK2nrxkhGDis1HA/" id="tron-academy-research-cta" target="_blank" rel="noopenner noreferrer">
                        <button>Join TRON Academy</button>
                    </a>
                </div>
                <div className="col-lg-6 col-12">
                    <img src={tronBull} className="img-fluid" alt="tron-bull-magnify-glass"/>
                </div>
            </div>
        </div>

    );
};


export default Academy;

export const pageQuery = graphql`
query {
  allWpAcademyPartner(sort: {order: ASC, fields: academy_partner_info___order}) {
    edges {
      node {
        title
        academy_partner_info {
          partnerLogo {
                sourceUrl
          }
          order
        }
      }
    }
  }
}
`
